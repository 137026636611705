import dayjs from "dayjs";

import { DateRange } from "../core/utils/dates";
import { AnalysisType } from "app/core/klever/feedback/types";

import { AppStoreType, FeedbackAnalysisItemType } from "../../integrations/klassy";
import { FeedbackSourceType } from "../../integrations/klassy/feedback-api-v2-schema";

import { SourceOriginServiceType } from "app/sources/types/shared";

export type Range<T> = {
  start: T;
  end: T;
};

export type KleverEnvironment = "development" | "staging" | "production";

export function getEnvironment(): KleverEnvironment {
  const { hostname } = window.location;

  if (hostname.includes("klever.kraftful.com") || hostname.includes("analytics.kraftful.com"))
    return "production";
  if (
    hostname.includes("klever-staging.kraftful.com") ||
    hostname.includes("kohort-staging.kraftful.com")
  )
    return "staging";

  return "development";
}

export function isIntString(val: string): boolean {
  return /^\d+$/.test(val);
}

export function getAppstoreType(encodedAppId: string): AppStoreType {
  const [appId, _country] = encodedAppId.split(":") as [string, string?];
  return isIntString(appId) ? "ios" : "google_play";
}

export function encodeAppId(appId: string, country: string): string {
  return `${appId}:${country}`;
}

//These are the only ones active, we no longer support the others
const supportSourceOriginServices = new Set([
  "ZENDESK",
  "HUBSPOT",
  "INTERCOM",
  "FRONT",
  "DIXA",
  "SLACK",
]);
export function isSupportSourceWithOriginService(
  sourceOriginService: SourceOriginServiceType
): boolean {
  return supportSourceOriginServices.has(sourceOriginService);
}

const transcriptSourceTypes = new Set(["GONG", "KRAFTFUL"]);

export function isTranscriptSource(sourceOriginService: SourceOriginServiceType): boolean {
  return transcriptSourceTypes.has(sourceOriginService);
}

export function getFeedbackSummaryTableTitle(
  analysisType: FeedbackAnalysisItemType,
  analysisSelection: AnalysisType,
  sourceType?: SourceOriginServiceType
): string {
  const isSupport = sourceType ? isSupportSourceWithOriginService(sourceType) : false;
  const isTranscript = sourceType ? isTranscriptSource(sourceType) : false;

  switch (analysisType) {
    case FeedbackAnalysisItemType.request:
    case FeedbackAnalysisItemType.deepDiveRequest:
      switch (analysisSelection) {
        case AnalysisType.swot:
          return "Strengths";
        case AnalysisType.empathy:
          return "Says";
        case AnalysisType.what:
          return "What is";
        default:
          return "Top feature requests";
      }
    case FeedbackAnalysisItemType.lovedFeature:
    case FeedbackAnalysisItemType.deepDiveLovedFeature:
      switch (analysisSelection) {
        case AnalysisType.swot:
          return "Weaknesses";
        case AnalysisType.empathy:
          return "Feels";
        case AnalysisType.what:
          return "What wows";
        default:
          return isSupport || analysisSelection === AnalysisType.secondary
            ? "Features mentioned"
            : "Most loved features";
      }
    case FeedbackAnalysisItemType.complaint:
    case FeedbackAnalysisItemType.deepDiveComplaint:
      switch (analysisSelection) {
        case AnalysisType.swot:
          return "Opportunities";
        case AnalysisType.empathy:
          return "Does";
        case AnalysisType.what:
          return "What if";
        default:
          return isTranscript ? "Pain points" : "Main complaints";
      }
    case FeedbackAnalysisItemType.competitor:
    case FeedbackAnalysisItemType.deepDiveCompetitor:
      switch (analysisSelection) {
        case AnalysisType.swot:
          return "Threats";
        case AnalysisType.empathy:
          return "Thinks";
        case AnalysisType.what:
          return "What works";
        default:
          return isSupport || analysisSelection === AnalysisType.secondary
            ? "Categories mentioned"
            : isTranscript
            ? "Key takeaways"
            : "Brands mentioned";
      }
    case FeedbackAnalysisItemType.summary:
      return "Summary";
    default:
      throw new Error(`Unknown source type: ${analysisType}`);
  }
}

export function getExportFileName(
  sourceType: string,
  analysisType: FeedbackAnalysisItemType,
  dateRange: DateRange,
  fileExtension: string
): string {
  const displaySourceType = sourceType === FeedbackSourceType.appNew ? "app" : sourceType;

  const isSupport = isSupportSource(sourceType);

  let displayAnalysisType: string;
  switch (analysisType) {
    case FeedbackAnalysisItemType.competitor:
      displayAnalysisType = isSupport ? "categories" : "competitors";
      break;
    case FeedbackAnalysisItemType.lovedFeature:
      displayAnalysisType = isSupport ? "features" : "loved";
      break;
    case FeedbackAnalysisItemType.request:
      displayAnalysisType = "requests";
      break;
    case FeedbackAnalysisItemType.complaint:
      displayAnalysisType = "complaints";
      break;
    default:
      throw new Error(`Unknown source type: ${sourceType}`);
  }

  const baseName = [
    "Kraftful",
    fileExtension.toUpperCase(),
    displaySourceType,
    displayAnalysisType,
    dayjs(dateRange.dateStart).format("MMM-DD"),
    dayjs(dateRange.dateEnd).format("MMM-DD"),
  ].join("-");

  return `${baseName}.${fileExtension}`;
}

/** @deprecated Use `groupBy` from lodash instead. Note: Once further browser support, we can just use Object.groupBy */
export function toMap<T extends Record<string, any>, K extends keyof T>(
  arr: T[],
  key: K
): Record<T[K], T> {
  return arr.reduce((acc, cur) => {
    return { ...acc, [cur[key]]: cur };
  }, {} as Record<T[K], T>);
}

/** @deprecated Use `groupBy` from lodash instead. Note: Once further browser support, we can just use Object.groupBy */
export function toMapWithDuplicates<T extends Record<string, any>, K extends keyof T>(
  arr: T[],
  key: K
): Record<T[K], T | T[]> {
  return arr.reduce((acc, cur) => {
    const existing = acc[cur[key]];
    if (existing !== undefined) {
      return { ...acc, [cur[key]]: Array.isArray(existing) ? [...existing, cur] : [existing, cur] };
    }
    return { ...acc, [cur[key]]: cur };
  }, {} as Record<T[K], T | T[]>);
}

export function base64Encode(value: string): string {
  return Buffer.from(value).toString("base64");
}

export function base64UrlEncode(value: string): string {
  return Buffer.from(value).toString("base64url");
}

export function base64UrlDecode(value: string): string {
  return Buffer.from(value, "base64url").toString();
}

/** @deprecated Support source types should now be handled through the API */
const supportSourceTypes = new Set([
  "dixa",
  "freshdesk",
  "front",
  "intercom",
  "jira",
  "kustomer",
  "zendesk",
  "hubspot",
  "slack",
]);

/** @deprecated Support source types should now be handled through the API */
export function isSupportSource(sourceType: string): boolean {
  return supportSourceTypes.has(sourceType);
}
